@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300&family=Montserrat:wght@300;400&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Manrope", sans-serif;
  font-family: "Montserrat", sans-serif;
  background-color: #faf9f6;
  overflow-x: hidden;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.placeholder-black::placeholder {
  color: black;
}

.placeholder-black-dk::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

/********* Dark Mode Login *******/

.dark {
  background-color: #141414;
  color: white;
}

.dark-bg {
  background-color: #000000;
}

.dark-text {
  color: rgb(0, 0, 0);
}

.dark-text-white {
  color: #fff;
}

.dark-button {
  background-color: #fff;
}

.dark-button-login {
  background-color: #000000;
  border: 1px solid #ffffff;
}

/********* Dark Mode Register *******/

.dark-register-bg {
  background-color: #141414;
}

.dark-register-bt {
  background-color: #141414;
  color: rgba(255, 255, 255, 0.6);
}

.dark-register {
  background-color: #1b1b1b;
}

.dark-login-ig {
  color: rgba(255, 255, 255, 0.6);
}

.active-buttonH {
  font: bold;
  text-align: left;
}

.active-buttonD {
  font: bold;
  text-align: left;
}

.active-buttonD i::after,
.active-buttonH i::after {
  content: "";
  display: block;
  width: 130%;
  border-radius: 5px;
  transform: translateX(-11%);
  height: 5px; /* Altura de la barra debajo del ícono */
  background-color: #000000; /* Color de la barra */
  margin-top: 5px; /* Espaciado entre el ícono y la barra */
  transition: height 0.3s ease; /* Transición suave de la altura */
}

.contain-all {
  margin-right: 38%;
}

.prevent-word-break {
  overflow-wrap: break-word;
}

/********* Dark Mode Home *******/
.dark-login-bg {
  background: #1b1b1b;
}

/********* Dark Mode Profiles *******/

.bg-profile-img {
  background: #565555;
}

.placeholder-black-p {
  color: #fff;
}

.placeholder-black-p::placeholder {
  color: rgba(255, 255, 255);
}

.dark-border-white {
  border-color: #fff;
}

.label-profile {
  background: rgba(125, 125, 125, 0.6);
}

.label-profile:hover {
  background: rgba(125, 125, 125, 0.2);
}

/********* Tab view *******/

.tab-indicator {
  position: relative;
  bottom: 0;
  left: 0;
  width: auto;
  height: 3px; /* Altura de la línea */
  background-color: rgb(64, 64, 236); /* Color de la línea */
  border-top-left-radius: 5px; /* Borde superior izquierdo redondeado */
  border-top-right-radius: 5px; /* Borde superior derecho redondeado */
  transform-origin: center;
  transition: transform 0.3s ease-in; /* Agrega una animación de transición */
}

.messages {
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-bottom: 5%;
}

.message {
  max-width: 70%;
  margin: 5px;
  padding: 10px;
  border-radius: 8px;
}

.sent {
  align-self: flex-end;
  background: linear-gradient(
    to right,
    #5888e0,
    #285be0
  ); /* Degradado de derecha a izquierda */
  color: white;
  border-top-left-radius: 10px; /* Radio del borde superior izquierdo */
  border-top-right-radius: 0; /* Sin radio en el borde superior derecho */
  border-bottom-right-radius: 10px; /* Radio del borde inferior derecho */
  border-bottom-left-radius: 10px; /* Radio del borde inferior izquierdo */
  padding: 10px; /* Ajusta según sea necesario */
}

.received {
  align-self: flex-start;
  background-color: #ffffff;
  border-top-left-radius: 0; /* Radio del borde superior izquierdo */
  border-top-right-radius: 10px; /* Sin radio en el borde superior derecho */
  border-bottom-right-radius: 10px; /* Radio del borde inferior derecho */
  border-bottom-left-radius: 10px; /* Radio del borde inferior izquierdo */ /* Light gray color for received messages */
  color: black;
}

.input-container {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 58%;
  display: flex;
  align-items: center;
  padding: 10px;
  padding-right: 1%;
  padding-left: 2%;
}

.input-container textarea {
  width: calc(100% - 20px);
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #FFF;
  margin-right: 0%;
}

.input-container button {
  padding: 15px;
  border: 1px solid #ccc;
  border-left: none; /* Elimina el borde superior */
  border-radius: 0 5px 5px 0; /* Curvas en la esquina derecha arriba y derecha abajo */
  margin-left: -0.3%;
  background-color: #FFF;
  cursor: pointer;
}

.input-container button img {
  width: 25px;
  height: 25px;
}

.conversation-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  padding: 2%;
}
.pdf-container {
  width: 100vw; /* Set the width to 100% of the viewport width */
  height: 100vh; /* Set the height to 100% of the viewport height */
  overflow: hidden; /* Hide any potential overflow */
  margin: 0; /* Remove margin to ensure the iframe covers the entire screen */
  padding: 0; /* Remove padding to ensure the iframe covers the entire screen */
}

iframe {
  width: 100%; /* Set the width of the iframe to 100% */
  height: 100%; /* Set the height of the iframe to 100% */
  border: none; /* Remove iframe border */
}

.otp-input {
  display: flex;
  justify-content: space-between;
  width: 200px; /* Adjust the width as needed */
}

.partitioned input {
  width: calc(
    100% / 7
  ); /* Assuming you have 6 input spaces, adjust accordingly */
  padding: 8px;
  text-align: center;
  height: 70px;
  border: 1px solid #888888; /* Blue outline color */
  border-radius: 10px;
  margin-right: 15px; /* Adjust the spacing between input spaces */
  font-size: 25px; /* Adjust the font size as needed */
}

/** Responsive **/
.navbar {
  width: auto;
}

.bottombar {
  display: none;
}

.contain-principal {
  display: flex;
}

.input-style:focus {
  outline-color: #688bff;
}

.post-container {
  display: flex;
  flex-direction: column;
}

.button-style {
  background: linear-gradient(267deg, #8e1da1 0%, #2d015a 100%);
}

.post-empresa {
  display: flex;
  flex-direction: column;
}

.opacity {
  opacity: 20%;
}

.response-hidden{
  display: block;
}

.response-show{
  display: none;
}

.conversation-response{
  display: none;
}
@media (max-width: 768px) {
  .responsive-post{
    padding: 1%;
  }
  .responsive-postimg{
    position: relative;
  }
  .input-container textarea {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    margin-right: -12%;
  }

  .input-container{
    width: 100%;
    padding-bottom: 15%;
  }

  .conversation-container{
    display: none;
  }
  .conversation-response{
    display: block;
  }
  .response-show{
    display: block;
  }
  .response-hidden{
    display: none;
  }
  .post-search {
    margin-left: 4%;
  }
  .search-txt {
    width: 90%;
  }
  .bottombar {
    display: block;
    bottom: 0;
    position: fixed;
  }
  .respohidden {
    display: none;
  }
  .create-postem {
    width: 96%;
    margin-left: 2%;
    margin-right: 0;
    margin-top: -20px;
    padding-right: 0;
    padding-left: 0;
  }
  .contain-begin {
    overflow-x: hidden;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .contain-response-begin {
    margin-left: 10%;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    width: auto;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    flex-wrap: wrap;
  }
  .imagenresponb {
    margin-right: 27%;
  }
  .contain-response-begin > h1 {
    font-size: 50px;
  }
  .contain-response {
    width: auto;
    overflow-x: hidden;
    flex-wrap: wrap;
  }
  .p-fa {
    margin-left: 0;
    margin-right: 2px;
    padding-left: 0;
    padding-right: 0;
  }
  .margin-top {
    margin-top: 0;
    padding-top: 0;
  }
  .sidebar1 {
    display: none;
  }

  .active-buttonH {
    position: relative;
    width: 100%;
  }
  .active-buttonD {
    position: relative;
    width: 100%;
  }
  .sidebarcontain {
    width: 100%;
  }
  .create-post {
    width: 96%;
    margin-left: 2%;
    margin-right: 0;
    margin-top: -20px;
    padding-right: 0;
    padding-left: 0;
  }
  .margin-rightmove {
    margin-right: 0;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .post-post {
    width: 96%;
    margin-left: 2%;
    margin-right: 0;
    margin-bottom: 2%;
    margin-top: 0%;
    padding-right: 0;
    padding-left: 0;
  }
  .post-container {
    flex-direction: row;
    flex-wrap: wrap;
    transform: translateX(0%);
  }
  .post-empresa {
    flex-direction: row;
    flex-wrap: wrap;
    transform: translateX(0%);
  }
  .modalres {
    width: 94%;
  }
  .textareares {
    width: 92%;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .responsive-postimg{
    position: relative;
  }
  .responsive-post{
    padding: 1%;
  }
  .input-container{
    width: 100%;
    padding-bottom: 10%;
  }
  .conversation-container{
    display: none;
  }
  .conversation-response{
    display: block;
  }
  .response-show{
    display: block;
  }
  .response-hidden{
    display: none;
  }
  .iconeres {
    margin-top: 1%;
    font-size: 200%;
  }
  .bottombar {
    display: block;
    bottom: 0;
    position: fixed;
  }
  .respohidden {
    display: none;
  }
  .create-postem {
    width: 96%;
    margin-left: 2%;
    margin-right: 0;
    margin-top: -20px;
    padding-right: 0;
    padding-left: 0;
  }
  .contain-begin {
    overflow-x: hidden;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .contain-response-begin {
    margin-left: 10%;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    width: auto;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    flex-wrap: wrap;
  }
  .imagenresponb {
    margin-right: 27%;
  }
  .contain-principal-begin > h1 {
    font-size: 50px;
  }
  .contain-response {
    width: auto;
    overflow-x: hidden;
    flex-wrap: wrap;
  }
  
  .p-fa {
    margin-left: 0;
    margin-right: 2px;
    padding-left: 0;
    padding-right: 0;
  }
  .margin-top {
    margin-top: 0;
    padding-top: 0;
  }
  .sidebar1 {
    display: none;
  }

  .active-buttonH {
    position: relative;
    width: 100%;
  }
  .active-buttonD {
    position: relative;
    width: 100%;
  }
  .sidebarcontain {
    width: 100%;
  }
  .create-post {
    width: 96%;
    margin-left: 2%;
    margin-right: 0;
    margin-top: -20px;
    padding-right: 0;
    padding-left: 0;
  }
  .margin-rightmove {
    margin-right: 0;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .post-post {
    width: 96%;
    margin-left: 2%;
    margin-right: 0;
    margin-bottom: 2%;
    margin-top: 0%;
    padding-right: 0;
    padding-left: 0;
  }
  .post-container {
    flex-direction: row;
    flex-wrap: wrap;
    transform: translateX(0%);
  }
  .post-empresa {
    flex-direction: row;
    flex-wrap: wrap;
    transform: translateX(0%);
  }
  .modalres {
    width: 94%;
  }
  .textareares {
    width: 92%;
  }
}
